<template>
    <!-- 主内容 -->
    <div>
        <Header />
        <div class="course-main">
            <Advert></Advert>
            <div class="course-main-wrap">
                <div class="course-main-header flex y-center x-between mb24">
                    <div class="course-main-header-title">高校课程</div>
                    <div class="course-main-header-input">
                        <wj-input
                            placeholder="请输入高校名称"
                            suffix-icon="wj-icon-search"
                            v-model="school"
                            @keydown.enter.native="searchShool"
                        >
                        </wj-input>
                    </div>
                </div>
                <div class="course-main-content flex" v-loading="loading">
                    <template v-if="schoolList.length">
                        <CourseItem
                            v-for="(item, index) in schoolList"
                            :key="index"
                            :itemData="item"
                            renderType="school"
                            :img="imgList[index]"
                            @showLogin="showLogin"
                        />
                    </template>

                    <div class="empty-area" v-if="schoolList.length < 1 && !loading">
                        <wj-image :src="require('@/assets/images/empty-data.png')"></wj-image>
                        <span class="sign">暂无课程</span>
                    </div>
                </div>
                <!-- 分页 -->
                <div class="course-main-footer">
                    <wj-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="current"
                        :page-size="size"
                        layout="prev, pager, next, jumper"
                        :total="total"
                        :hide-on-single-page="true"
                    >
                    </wj-pagination>
                </div>
            </div>
        </div>
        <Footer2 />
        <Footer />
        <Login v-model="showLoginDialog"></Login>
    </div>
</template>

<script>
import CourseItem from '@/pages/components/course-item.vue';
import { httpGetPublicSchoolList } from '@/http/public/index.js';
import { Button, Image, Input, Pagination } from '@wenjuan/ui';
import { httpGetImageList } from '@/http/public/index.js';
import Header from '@/layout/header.vue';
import Footer from '@/layout/footer.vue';
import Footer2 from '@/layout/footer2.vue';
import Advert from '@/layout/advert.vue';
import Login from '@/components/login/index.vue';
export default {
    name: 'course',
    components: {
        CourseItem,
        'wj-button': Button,
        'wj-image': Image,
        'wj-input': Input,
        'wj-pagination': Pagination,
        Login,
        Header,
        Footer,
        Footer2,
        Advert,
    },
    data() {
        return {
            schoolList: [],
            school: '',
            size: 16,
            current: 0,
            total: 0,
            imgList: [], //图片列表
            loading: false,
            showLoginDialog: false,
        };
    },
    created() {
        this.getSchoolList({
            current: 0,
            size: this.size,
            school: this.school,
        });
    },
    mounted() {},
    methods: {
        // 封装 获取学校列表
        async getSchoolList(pageInfo) {
            this.loading = true;
            try {
                let res = await httpGetPublicSchoolList(pageInfo);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                let schoolList = res.data.data;
                let imgList = schoolList.map((item) => {
                    return item.coverImageId;
                });
                let total = Number(res.data.total);
                if (imgList.length) {
                    let imgItem = await this.getImageList(imgList);
                    this.imgList = imgItem;
                }
                this.total = total;
                this.schoolList = schoolList;
                this.loading = false;
            } catch {
                this.loading = false;
            }
        },
        handleCurrentChange(current) {
            this.getSchoolList({
                current: current - 1,
                size: this.size,
                school: this.school,
            });
        },
        getImageList(list) {
            return new Promise((resolve, reject) => {
                httpGetImageList(list)
                    .then((res) => {
                        // console.log('图片列表', res);
                        // this.imgList = res.data.data;
                        resolve(res.data.data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        searchShool() {
            this.getSchoolList({
                current: 0,
                size: this.size,
                school: this.school,
            });
        },
        showLogin() {
            this.showLoginDialog = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.course-main {
    background-color: #f3f5f7;
    padding-bottom: 105px;
    // 版心布局
    .course-main-wrap {
        width: 1330px;
        margin: 30px auto 0;
        .course-main-header {
            // height: 95px;
            .course-main-header-title {
                font-size: 18px;
                font-family: PingFangSC;
            }
            .course-main-header-input {
                width: 310px;
                .wj-input {
                    ::v-deep input {
                        border-radius: 19px;
                        border: 0;
                        text-indent: 4px;
                    }
                }
            }
        }
        .course-main-content {
            width: 1330px;
            flex-wrap: wrap;
            min-height: 560px;
            .empty-area {
                width: 100%;
                height: 560px;
                display: flex;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .wj-image {
                    width: 250px;
                    height: 150px;
                }
                .sign {
                    margin-top: 15px;
                    color: #666666;
                }
            }
        }
        .course-main-footer {
            text-align: center;
            // 去掉边框
            .wj-pagination li,
            .wj-pagination button {
                background-color: #f3f5f7;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
                &:not(.active) {
                    border: 1px solid rgba(255, 255, 255, 0);
                }
            }
            .wj-pagination input,
            .wj-pagination__jump {
                background-color: #f3f5f7;
                border-radius: 3px;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
            }
        }
    }
}
</style>
